import React, { Fragment,useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import PageTitleServicesInsideCom from '../blocks/page-title/PageTitleServicesInsideCom'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';
import Introduction from '../components/services/Introduction';
import WhyComponent from '../components/services/WhyComponent';
const ServicesInside11 = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    const list = [
        '综合性多应用平台，可治疗多种医学美容病症，涵盖 FDA 批准的 72 种适应症。',
        'Alma Harmony XL PRO 结合了全方位的先进技术和卓越的临床效果，创造了完美的美学Harmony。',
        'Alma Harmony XL Pro 采用创新的独一无二的技术，可治疗 72 种 FDA 批准的医学美容适应症，使其成为市场上最通用的多模式平台。',
        '新的创新包括精确瞄准组织不同层的深度控制、点阵 Q 开关激光、多色纹身去除、增强IPL/DPL 安全性的独特 In-MotionTM 技术，以及激光与真空技术相结合的革命性痤疮治疗。',
    ]
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, []);
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark"  position='relative'  />

            <main id="main" className="site-main" style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                <PageTitleServicesInsideCom str={'Harmony 激光'} />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                            <div className="object-fit-cover" style={{ display: "flex", justifyContent: "space-around", columnGap: "1rem" }}>
                                                    <img src="assets/img/placeholder/Harmony 治疗.jpg"  alt="Hair Treatments" />
                                                    <img src="assets/img/placeholder/HARMONY.jpg" alt="Hair Treatments" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                                        </div>
                                    </div> */}

                                    <Introduction textIndent={true} list={list} title={'特征'}></Introduction>

                                    {/* <WhyComponent list={whyList} title='为什么选择 ULTRAFORMER III ？' /> */}

                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">适用范围</h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>SR</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>痤疮和衰老的影响会导致疤痕累积和毛孔粗大。 Alma 的纹理护理可触发皮肤的自然愈合过程，实现真皮重塑，从而改善皮肤纹理并展现更光滑的皮肤。</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>祛斑</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>肤色不均是由阳光照射、荷尔蒙变化、遗传、环境影响等引起的常见问题。 Alma 的色素沉着治疗可淡化并淡化这些病变，打造更均匀的肤色。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>NIR</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>随着胶原蛋白的产生随着时间的推移而减少，面部和颈部周围会出现松弛、下垂的皮肤区域。Alma 的嫩肤治疗可刺激胶原蛋白和弹性蛋白纤维的合成，使成熟肌肤看起来更年轻、更丰润。随着皮肤老化，由于胶原蛋白和弹性蛋白纤维的减少，皮肤会失去强度、柔韧性和弹性。这会导致凹槽形成并发展成皱纹。 Alma 提供最佳的非侵入性技术来减少皱纹的出现。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Clear Skin</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>研究表明，城市环境和化妆品中的污染物、毒素和化学物质会损害和堵塞皮肤。 Alma 的三步DermaClear 护理可深入而温和地去除角质、清洁、保湿和滋养肌肤，从而立即焕发光彩。</span>
                                                        </li>


                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>玻尿酸瀑布护理</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90-120 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>氧气泡泡护理</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair styling</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair styling for occasion</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Consulting</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Trial hairstyling for occasion</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>All hair color</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Root color</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Highlights</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair toning</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInside11;