import React, { Fragment,useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleAbout from '../blocks/page-title/PageTitleAbout';
import AboutReviews from '../blocks/about/AboutReviews';
import AboutImg from '../blocks/about/AboutImg';
import AboutsUs from '../blocks/about/AboutUs';
import AboutUs1 from '../blocks/about/AboutUs1';
import AboutOurTeam from '../blocks/about/AboutOurTeam';
import AboutServices from '../blocks/about/AboutServices';
import AboutClients from '../blocks/about/AboutClients';
import { useLocation } from 'react-router-dom';
const About = () => {
    document.body.classList.add('page');
    const location = useLocation();
    const hash = location.hash;
    console.log(hash, 'sadasdasd');
    useEffect(() => {
        const element = document.getElementById(hash.slice(1));
        if (element) {
            element.scrollIntoView(true);
        }
    }, []);
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>About us | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark" position='relative' />

            <main id="main" className="site-main" style={{ paddingTop: "7.368rem", paddingBottom: "7.368rem" }}>
                <PageTitleAbout />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div className="clearfix" id='xini'>
                                <AboutImg />

                                <AboutsUs />

                                <div id="about-us-img" className="block spacer p-top-xl">
                                    <div className="about-img" >
                                        <div className="img object-fit">
                                            <div className="object-fit-cover" id='moerben'>
                                                <img src="assets/img/placeholder/墨尔本咨询室.jpg" alt="About us" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <AboutUs1></AboutUs1>

                                {/* <AboutOurTeam /> */}

                                {/* <AboutReviews /> */}

                                <AboutServices />

                                {/* <AboutClients /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default About;
