import React, { Component } from 'react';
import Swiper from 'swiper';

class ServicesHomeEn extends Component {
    componentDidMount() {
        this.swiper = new Swiper('.adv-slider-services .adv-swiper-container', {
            autoplay: {
                delay: 3000, // 自动切换的时间间隔，单位为毫秒

                disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay。默认为true：停止。
            },
            speed: 2000,
            loop: true,
            // ADV swiper options
            noSwipingClass: 'adv-swiper-no-swiping',
            containerModifierClass: 'adv-swiper-container-',
            slideClass: 'adv-swiper-slide',
            slideBlankClass: 'adv-swiper-slide-invisible-blank',
            slideActiveClass: 'adv-swiper-slide-active',
            slideDuplicateActiveClass: 'adv-swiper-slide-duplicate-active',
            slideVisibleClass: 'adv-swiper-slide-visible',
            slideDuplicateClass: 'adv-swiper-slide-duplicate',
            slideNextClass: 'adv-swiper-slide-next',
            slideDuplicateNextClass: 'adv-swiper-slide-duplicate-next',
            slidePrevClass: 'adv-swiper-slide-prev',
            slideDuplicatePrevClass: 'adv-swiper-slide-duplicate-prev',
            wrapperClass: 'adv-swiper-wrapper',
            navigation: {
                disabledClass: 'adv-swiper-button-disabled',
                hiddenClass: 'adv-swiper-button-hidden',
                lockClass: 'adv-swiper-button-lock',
                nextEl: '.adv-2-swiper-button-next',
                prevEl: '.adv-2-swiper-button-prev'
            },
            spaceBetween: 0,
            slidesPerView: 'auto'
        });
    }

    render() {
        return (
            <section id="services" className="block" style={{ paddingTop: "7.368rem" }}>
                <div className="adv-slider-services">
                    <div className="adv-swiper-container">
                        <div className="adv-swiper-wrapper services-items clearfix">
                            <a title="至奢抗衰护理系列" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Delux Ant-aging Facial treatment</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/至奢.png" alt="至奢抗衰护理系列" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            {/* <a title="时空胶囊护理" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn1"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">WISH Pro instant facial treatment with serum capsule</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/时空.png" alt="Hair Treatments" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a> */}

                            <a title="基础护理系列" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn2"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Basic Facial treatment</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/基础护理图片.png" alt="基础护理系列" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn3"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Eye care treatment</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/眼部护理图片.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>


                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn4"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Body treatment</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/身体舒缓new.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn5"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Japanese Bone Adjustment</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/3DFacebone_new.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>


                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn7"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">DP Cell Renewal</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/干细胞new.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn8"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Baby Skin Treatment</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/水光针图片.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn6"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Secret RF</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/黄金微针.jpg" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn9"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">SOFWAVE</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/softwave_new.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            {/* <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn10"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">ALMA HARMONY XL PRO</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/alma_new.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a> */}

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn11"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">ULTRAFORMER III</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/7D_new.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn12"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Ultraformer MPT</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/10DMPT.jpg" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn13"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Lutronic Hollywood Spectra</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/Hollywood2.jpg" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn11"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">WISH Pro</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/wishpro.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn15"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Alma Harmony Laser</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/Harmony 治疗.jpg" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn16"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Lucas Laser</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/lucas 仪器.jpg" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-insideEn17"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Postpartum Repair</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">More Details</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/产后修复.jpg" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>


                        </div>

                        <div className="adv-2-swiper-button-prev">
                            <span>
                                <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto">Previous</span>
                            </span>
                        </div>

                        <div className="adv-2-swiper-button-next">
                            <span>
                                <span className="btn btn-lg btn-after-dot btn-link border-0 p-0 min-w-auto">Next</span>
                            </span>

                        </div>


                    </div>

                </div>

            </section>
        );
    }
}

export default ServicesHomeEn;
