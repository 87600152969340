import React, { Fragment, useEffect} from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/HearderEn';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInsideCom'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServicesEn';
import HeaderEn from '../blocks/header/HearderEn';
import Introduction from '../components/services/Introduction';
import WhyComponent from '../components/services/WhyComponent';

const ServicesInsideEn17 = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, []);
    const whyList = [
        {
            title: 'Diastasis Recti',
            content: "During pregnancy, the abdominal muscles are under great pressure, leading to the phenomenon of rectus abdominis separation (Diastasis Recti). Rectus abdominis repair helps reshape the core muscles, strengthen abdominal strength, improve posture, relieve waist and back discomfort, and improve overall function through traditional treatment techniques."
        },
        {
            title: 'Hip Dips Adjustment',
            content: "Many women will experience changes in the pelvic area after childbirth, which may lead to the phenomenon of false hip width. This condition not only affects the appearance, but may also cause pain and discomfort in the lower limbs. Through targeted treatment, the pelvic position can be adjusted, the leg lines can be improved, the muscle stability can be enhanced, and the body shape can be restored. "
        },
        {
            title: 'Pubic Adjustment',
            content: "The pubic area may be damaged or deformed after childbirth, causing pain and limited movement. The pubic bone repair project uses professional osteopathic techniques to help restore the normal function of the pubic bone, relieve pain, promote blood circulation, and improve athletic ability. "
        },
        {
            title: 'Pelvic Adjustment',
            content: "The pelvis is under great pressure during childbirth, which may cause discomfort or dysfunction. Pelvic bone repair combined with osteopathic techniques aims to restore the structure and function of the pelvis, reduce pain, increase the strength of the pelvic floor muscles, improve urinary incontinence and other problems, and enhance overall physical fitness."
        },
        {
            title: 'Postpartum body management',
            content: "This treatment focuses on improving the overall body shape and health level. Through systematic adjustments, it helps new mothers regain confidence, shape a beautiful body, improve body posture, and enhance physical fitness."
        }
    ]
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <HeaderEn logoColor="dark"  position='relative'  />

            <main id="main" className="site-main" style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                <PageTitleServicesInside str={'Postpartum repair'} />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                            <div className="object-fit-cover" style={{ display: "flex", justifyContent: "space-around", columnGap: "1rem" }}>
                                                    <img src="assets/img/placeholder/产后修复.jpg"  alt="Hair Treatments" />
                                                    <img src="assets/img/placeholder/产后修复2.jpg"  alt="Hair Treatments" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                                        </div>
                                    </div> */}
                                    <Introduction list={['Postpartum repair is an important topic of concern to many new mothers. Through targeted treatment programs, mothers can effectively restore the function and beauty of their bodies and regain their confidence. Under the guidance of professional rehabilitation therapists, a personalized repair plan is developed to achieve the best results.']}></Introduction>
                                    <WhyComponent list={whyList}  title='Treatments'/>

                                   {false && <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">Treatment program introduction </h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0" }}>抗衰老精华</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>荷荷巴油中强大的抗氧化剂有助于抚平细纹和皱纹。完美的天然保湿剂，防止脱水</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>BTX</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>通过限制面部肌肉收缩来减少皱纹，作为一种局部治疗，模仿肉毒®杆菌毒素在皮肤中的作用，而没有负面的副作用</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>新能源</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>纯生物能源，让肌肤更滋润滋养，无需针头即可减少深层皱纹</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>年龄控制</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>含有 Progeline 肽的水基乳液对表皮和真皮有直接影响。它具有重塑作用，改善皮肤松弛和皱纹的外观。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>镇静牛奶</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>使用这种金盏花油基产品的防腐、抗菌特性，您的皮肤可以看起来更清爽、更滋养，并赢得与烦人的皮肤感染的战斗。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>胶原</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>含有纯胶原蛋白基质，可保护皮肤免受有害辐射，增强免疫系统并对抗自由基，从而延缓皮肤老化和细胞退化</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>修复</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>刺激细胞修复和伤口愈合，改善皮肤弹性、紧致度并明显减少皱纹</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>轮廓</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>旨在恢复年轻的外观，抗衰老敏感皮肤，通过针对面部结构的特定深度来对抗人脸黑眼圈，并治疗局部面部问题，如皱纹、皮肤松弛、色素沉着过度和疤痕。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>去角质剂</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>更新和活化您的皮肤。使用富含维生素 E 和 A 的纤维素去角质的甜杏仁油，恢复年轻光泽。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>透明质</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>通过刺激胶原蛋白和弹性蛋白的合成，增加皮肤的保水能力，防止脱水，修复和滋润皮肤</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>白粉</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>通过阻断表皮黑色素并调节生物合成的程序淡化和均匀肤色</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>关心</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>基于 Abyssine® 657 - 来自生活在深海热液喷口的极端微生物的胞外多糖。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>回春</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>含有富含维生素 A、E 和 F 的乳木果油，使受损细胞恢复活力，同时恢复弹性和色调。Rejuvenation 为皮肤提供必需脂肪酸和保护皮肤再生所需的营养物质。</span>
                                                        </li>



                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>玻尿酸瀑布护理</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90-120 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>氧气泡泡护理</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair styling</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair styling for occasion</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Consulting</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Trial hairstyling for occasion</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>All hair color</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Root color</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Highlights</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair toning</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInsideEn17;