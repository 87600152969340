import React from 'react';

const PageTitleServicesInside14 = () => {
    return (
        <div id="page-title">
            <div className="wrapper text-center">
                <h1 className="large">WISH Pro 时空胶囊</h1>
            </div>
        </div>
    );
};

export default PageTitleServicesInside14;