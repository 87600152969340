import React, { Fragment, useEffect} from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/HearderEn';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInside'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServicesEn';
import PageTitleServicesInside8 from '../blocks/page-title/PageTitleServicesInsideEn8';
import HeaderEn from '../blocks/header/HearderEn';
import Introduction from '../components/services/Introduction';

const ServicesInsideEn6 = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, []);
    const list = [
        'SECRET RF micro-needle fractional system is a leading technology for skin lifting, scarring, tightening and rejuvenation, using precisely controlled bi-polar radio frequency in conjunction with minimally invasive insulated or non-insulated micro-needles.',
        'The powerful technology combination induces dermal coagulation for skin tightening, wrinkle and pore size reduction, acne scar treatment and axillary hyperhidrosis.',
        'The synergy between RF and micro-needles has delivered one of the most technologically advanced forms of skin rejuvenation therapy available. It also helps to reduce treatment time and downtime significantly when compared to fractional laser based treatments. All skin types can be treated by SECRET RF with very low risk of thermal damage.',
        'SECRET RF works by applying precisely controlled RF energy directly into specific depths of the skin with minimally invasive, precision engineered micro-needles. Secret RF is particularly useful for treating both deep and shallow wrinkles, and acne scars.',
        'Australia’s Most Popular RF Needling Device.',
    ]
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <HeaderEn logoColor="dark"  position='relative'  />

            <main id="main" className="site-main" style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                <PageTitleServicesInside8 />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover">
                                                    <img src="assets/img/placeholder/黄金微针.jpg" alt="Hair Treatments" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                                        </div>
                                    </div> */}
                                    <Introduction list={list} tips={'Micro-needle RF for scars, rejuvenation and dermal density'} tipsSize={'32px'} title={'Why gold microneedle is needed？'}></Introduction>

                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">Service Details</h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Improved tone and texture</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Fine lines and wrinkles</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Acne and surgical scars</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90-120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Stretch marks</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90 mins</span> */}
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Pore reduction</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90 mins</span> */}
                                                        </li>
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Wrinkles</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90 mins</span>
                                                        </li> */}
                                                    </ul>
                                                    <BackToServices />
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>玻尿酸瀑布护理</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90-120 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>氧气泡泡护理</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair styling</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair styling for occasion</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Consulting</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Trial hairstyling for occasion</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>All hair color</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Root color</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Highlights</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair toning</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInsideEn6;