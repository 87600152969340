import React from 'react';
import HeaderLogo from '../../blocks/logo/HeaderLogo';
import SearchModal from '../../components/modal/SearchModal';
import MenuModal from '../../components/modal/MenuModal';
import Actions from "../actions/ActionsEn";
import MenuModalEn from '../../components/modal/MeauModalEn';
import SearchModalEn from '../../components/modal/SearchModalEn';
import { Link } from 'react-router-dom';

const HeaderEn = ( { logoColor, position} ) => {
    return (
        <header id="header" className="site-header"  style={{position: position?position:""}}>
            <div className="wrapper">
                <div className="header-content d-flex justify-content-between">
                    <div className="header-left align-self-center">
                        <div className="links">
                            <div className="links-items">
                                <div className="links-item">
                                    <a href={ process.env.PUBLIC_URL + "/HomeEn" } className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto">Home</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-center align-self-center">
                        <HeaderLogo logoColor={ logoColor } />
                    </div>

                    <div className="header-right d-flex justify-content-end">
                        <div className="d-flex align-items-center">
                            <SearchModalEn />
                            <Link to={'servicesEn'}>service</Link>
                            {/* <Link to={'about-usEn'}>about us</Link> */}
                            <Link to={'contactsEn'}>contact us</Link>
                            {/* <MenuModalEn /> */}
                        </div>

                        <Actions />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderEn;
