import React, { Fragment,useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInside11'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';
import Introduction from '../components/services/Introduction';
import WhyComponent from '../components/services/WhyComponent';

const ServicesInside11 = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    const whyList = [
        {
            title: '微观和宏观聚焦超声技术',
            content: "我们遵守临床和国际监管标准，以确保使用 MMFU 技术进行安全有效的手术。"
        },
        {
            title: '多功能和多深度',
            content: "将超声波均匀且快速地分布到多层皮肤和身体组织中，而无需直接接触表皮。"
        },
        {
            title: '非侵入性且疼痛更少',
            content: "ULTRAFORMER III 配备超声波能量的多深度墨盒仅针对特定的皮肤层，而不会损坏附近的组织。"
        },
        {
            title: '快速、精确的射击',
            content: "HIFU 驱动的换能器旨在重塑胶原蛋白以消除面部皱纹和下垂的皮肤，或收紧身体组织。"
        },
        {
            title: '双引擎：高峰值功率传感器',
            content: "高峰值功率有效地将能量传递到目标皮肤层，而不会将热量扩散到周围组织。"
        },
        {
            title: '7 个可互换的面部和身体手柄',
            content: "由 MMFU 技术辅助的独特多功手柄均专为特定的面部和身体应用而设计。"
        },
    ]
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, []);
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark"  position='relative'  />

            <main id="main" className="site-main" style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                <PageTitleServicesInside />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover">
                                                    <img src="assets/img/placeholder/7D_new.png" alt="Hair Treatments" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                                        </div>
                                    </div> */}


                                    <Introduction textIndent={true} list={['']} tips={'ULTRAFORMER III 是一款最佳的非侵入性超声波设备，可提升和收紧面部，使肤色年轻，收紧身体，塑造更苗条的轮廓。凭借精确的每次发射脉冲，HIFU 驱动的传感器旨在重塑胶原蛋白以消除面部皱纹和下垂的皮肤，或收紧身体组织。'}></Introduction>

                                    <WhyComponent list={whyList}  title='为什么选择 ULTRAFORMER III ？'/>


                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">服务项目</h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>法令纹</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>眼纹眼袋</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>抬头纹</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>双下巴</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>轮廓提拉</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>全脸（不含颈项和双下巴）</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>全脸</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>120 mins</span> */}
                                                        </li>

                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>玻尿酸瀑布护理</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90-120 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>氧气泡泡护理</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair styling</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair styling for occasion</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Consulting</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Trial hairstyling for occasion</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>All hair color</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Root color</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Highlights</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair toning</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInside11;