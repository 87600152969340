import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServicesHomeList extends Component {

    render() {
        const list = [{
            title: "Skin Care",
            list1: [{
                title: "Basic Facial Treatment",
                link: '/services-insideEn2'
            },
            {
                title: "WISH Pro",
                link: '/services-insideEn14'
            },
            {
                title: "Delux Anti-aging Facial Treatment",
                link: '/services-insideEn'
            },
            {
                title: "Eye Treatment",
                link: '/services-insideEn3'
            }],
            bgImg: "url(assets/img/placeholder/基础护理1.png)",
        }, {
            title: "DP Cell Renewal",
            list1: [{
                title: "DP Cell Renewal",
                link: '/services-insideEn7'
            },
            {
                title: "Secret RF",
                link: '/services-insideEn6'
            },
            {
                title: "Baby Skin",
                link: '/services-insideEn8'
            },
            {
                title: "SOFWAVE",
                link: '/services-insideEn9'
            },
            {
                title: "ULTRAFORMER III",
                link: '/services-insideEn11'
            },
            {
                title: "Ultraformer MPT",
                link: '/services-insideEn12'
            }],
            bgImg: "url(assets/img/placeholder/黄金微针.jpg)",
        }, {
            title: "Laser Treatment",
            list1: [{
                title: "Lucas Laser",
                link: '/services-insideEn16'
            },
            {
                title: "Harmony Laser",
                link: '/services-insideEn15'
            },
            {
                title: "Spectra Laser",
                link: '/services-insideEn13'
            }],
            bgImg: "url(assets/img/placeholder/水光针图片.png)",
        }];

        const list2 = [
            {
                title: "Body Detox",
                list1: [{ title: "Lymphatic Massage", link: "/services-insideEn4" },
                { title: "Cupping", link: "/services-insideEn4" },
                { title: "Herb Steam", link: "/services-insideEn4" },
                { title: "Slimming", link: "/services-insideEn4" },
                { title: "Guasha", link: "/services-insideEn4" }],
                bgImg: "url(assets/img/placeholder/身体舒缓护理.webp)"
            }, {
                title: "Japanese Bone Adjustment",
                list1: [{ title: "3D Face Bone Adjustment", link: "/services-insideEn5" },
                { title: "Unequal Length Legged Adjustment", link: "/services-insideEn5" },
                { title: "Humpback Adjustment", link: "/services-insideEn5" },
                { title: "Ribs Bone Adjustment", link: "/services-insideEn5" },
                { title: "Pubic Adjustment", link: "/services-insideEn5" },
                { title: "Pelvic Adjustment", link: "/services-insideEn5" }],
                bgImg: "url(assets/img/placeholder/整骨.jpg)"
            }, {
                title: "Postpartum Care",
                list1: [{ title: "Diastasis Recti", link: "/services-insideEn17" },
                { title: "Hip Dips Adjustment", link: "/services-insideEn17" },
                { title: "Pubic Adjustment", link: "/services-insideEn17" },
                { title: "Pelvic Adjustment", link: "/services-insideEn17" }],
                bgImg: "url(assets/img/placeholder/基础护理.png)"
            }
        ]

        const bgBox = {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            filter: 'blur(5px) brightness(0.5)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backdropFilter: 'blur(2px)',
            zIndex: '-1',
            borderRadius: '8px'
        }

        const style = {
            padding: '40px 20px',
            textAlign: 'center',
            borderRadius: '8px',
            height: "100%",
            display: "flex",
            flexDirection: "column",
            color: "rgb(239, 230, 232)"
        }

        return (
            <div className='p-top-xl block spacer' style={{ padding: '0 3rem' }}>
                <h2 style={{ textAlign: 'center', paddingBottom: "2rem" }}>Our Service</h2>
                <div>

                    <div style={{ padding: "2rem", background: 'rgb(239, 230, 232)', borderRadius: '10px', marginBottom: '2rem' }}>
                        <h2 style={{ textAlign: 'center' }}>Skin Care</h2>
                        <div className='row gutter-width-sm' style={{ position: 'relative' }}>
                            {list.map(item => {
                                return (
                                    <div className='col-xl-4 col-lg-4 col-md-8 col-sm-12' key={item.title} >
                                        <div style={{ ...style, position: "relative", zIndex: 2 }}>
                                            <div>
                                                <h3 style={{ paddingBottom: '10px', color: "#fff" }}>{item.title}</h3>
                                                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
                                                    {item.list1.map(items => {
                                                        return (
                                                            <Link style={{ lineHeight: "50px", fontSize: '24px', color: "rgb(239, 230, 232)" }} to={items.link}>{items.title}</Link>
                                                        )
                                                    })}
                                                </div>
                                                <div style={{ ...bgBox, backgroundImage: item.bgImg }}></div>

                                            </div>

                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                    <div className='row gutter-width-sm'>
                        {list2.map(item => {
                            return (
                                <div className='col-xl-4 col-lg-4 col-md-8 col-sm-12' key={item.title} style={{ marginBottom: '2rem' }}>
                                    <div style={{ ...style, position: "relative" }}>
                                        <div>
                                            <h3 style={{ paddingBottom: '10px', color: "#fff" }}>{item.title}</h3>
                                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
                                                {item.list1.map(items => {
                                                    return (
                                                        <Link style={{ lineHeight: "50px", fontSize: '24px', color: "rgb(239, 230, 232)" }} to={items.link}>{items.title}</Link>
                                                    )
                                                })}
                                            </div>
                                            <div style={{ ...bgBox, backgroundImage: item.bgImg }}></div>

                                        </div>

                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    {/* <div className='col-xl-4 col-lg-4 col-md-8 col-sm-12' style={{ marginBottom: '20px' }}>
    <div style={{ ...style, position: "relative" }}>
        <div>
            <h3 style={{ paddingBottom: '10px', color: '#fff' }}>皮肤管理</h3>
            <div className='row' style={{ justifyContent: "space-around" }}>
                <div style={{ flex: 1 }}>
                    <h4 style={{ color: "rgb(239, 230, 232)" }}>手法+仪器类</h4>
                    <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside2'}>基础护理系列</Link>
                    <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside14'}>WISH Pro</Link>
                    <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside'}>至奢抗衰护理系列</Link>
                    <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside3'}>眼部抗老护理</Link>
                </div>
                <div style={{ flex: 1 }}>
                    <h4 style={{ color: "rgb(239, 230, 232)" }}>大型仪器类</h4>
                    <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside7'}>干细胞治疗</Link>
                    <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside6'}>黄金微针</Link>
                    <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside8'}>水光焕亮</Link>
                    <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside9'}>索夫波</Link>
                    <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside11'}>ULTRAFORMER III</Link>
                    <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside12'}>10D MPT</Link>
                </div>
            </div>
            <div style={{ ...bgBox, backgroundImage: "url(assets/img/placeholder/至奢大图.png)" }}></div>

        </div>


    </div>

</div> */}

                </div>





                {/* <div className='row gutter-width-sm'>


                    <div className='col-xl-4 col-lg-4 col-md-8 col-sm-12' style={{ marginBottom: '20px' }}>
                        <div style={{ ...style, position: "relative" }}>
                            <div>
                                <h3 style={{ paddingBottom: '10px', color: '#fff' }}>Skin Management</h3>
                                <div className='row' style={{ justifyContent: "space-around" }}>
                                    <div style={{ flex: 1 }}>
                                        <h4 style={{ color: "rgb(239, 230, 232)" }}>Manual+Equipment</h4>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn2'}>Basic Care Series</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn14'}>WISH Pro</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn'} > Luxury Anti-Aging Care Series</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn3'}>Eye Anti-Aging Care</Link>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <h4 style={{ color: "rgb(239, 230, 232)" }}>Large Equipment</h4>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn7'}>Stem Cell Therapy</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn6'}>Golden Microneedle</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn8'}>Hydro Brightness</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn9'}>Sofwave</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn11'}>Juplatten Pro</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn12'}>10D MPT</Link>
                                    </div>
                                </div>
                                <div style={{ ...bgBox, backgroundImage: "url(assets/img/placeholder/至奢大图.png)" }}></div>

                            </div>


                        </div>

                    </div>
                    {list.map(item => {
                        return (
                            <div className='col-xl-4 col-lg-4 col-md-8 col-sm-12' style={{ marginBottom: '20px' }}>
                                <div style={{ ...style, position: "relative" }}>
                                    <div>
                                        <h3 style={{ paddingBottom: '10px', color: "#fff" }}>{item.title}</h3>
                                        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
                                            {item.list1.map(items => {
                                                return (
                                                    <Link style={{ lineHeight: "28px", fontSize: '24px', color: "rgb(239, 230, 232)" }} to={items.link}>{items.title}</Link>
                                                )
                                            })}
                                        </div>
                                        <div style={{ ...bgBox, backgroundImage: item.bgImg }}></div>

                                    </div>

                                </div>
                            </div>
                        )
                    })}
                </div> */}
            </div>
        );
    }
}

export default ServicesHomeList;