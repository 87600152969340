import React, { Fragment,useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInside9'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';
import Introduction from '../components/services/Introduction';
import WhyComponent from '../components/services/WhyComponent';

const ServicesInside9 = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, []);
    const list = [
        'Sofwave™ 是通过创新刺激新胶原蛋白生成来使皮肤焕发活力的最新技术。 Sofwave™ 的同步超声平行光束技术已获得 FDA 批准，可用于提升眼部、颏下和颈部，并减少细纹和皱纹。'
    ]
    const whyList = [
        {
            title: '经过临床验证的技术',
            content: "一项多中心临床研究评估了 Sofwave™技术对面部皱纹的临床效果，大多数患者在单次治疗 12周后获得改善或显着改善"
        },
        {
            title: '安全有效',
            content: "专有的同步超声平行束 SUPERB™ 技术与集成冷却 Sofcool™，同时确保不会损坏表皮或真皮下的底层结构。"
        },
        {
            title: '非侵入性',
            content: "超声波能量精确地穿过皮肤表面，以适当的深度和温度加热真皮中层组织，使胶原纤维恢复活力。治疗后患者可以很快恢复正常生活"
        },
        {
            title: '治疗所有皮肤类型',
            content: "深色皮肤类型更容易受到不同光疗法的烧伤。无论患者的皮肤类型如何，超声波治疗都是安全有效的"
        },
        {
            title: '患者满意度高',
            content: "在一项多中心临床研究中，72%的患者对其结果感到满意，并将其评为“改善”至“非常改善”"
        }
    ]
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark"  position='relative'  />

            <main id="main" className="site-main" style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                <PageTitleServicesInside />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover" style={{ display: "flex", justifyContent: "space-around", columnGap: "1rem" }}>
                                                    <div style={{ maxHeight: '700px', flex: '1' }}><img src="assets/img/placeholder/SOFWARE机器.jpg" style={{objectFit:"contain"}} alt="Hair Treatments" /></div>
                                                    <div style={{ maxHeight: '700px', flex: '2' }}><img src="assets/img/placeholder/sofwave证书.png" style={{objectFit:"contain"}} alt="Hair Treatments" /></div>
                                                    <div style={{ maxHeight: '700px', flex: '1' }}><img src="assets/img/placeholder/SOFWARE证书.jpg" style={{objectFit:"contain"}} alt="Hair Treatments" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                                        </div>
                                    </div> */}

                                    <Introduction textIndent={true} list={['']} tips={'Sofwave™ 是通过创新刺激新胶原蛋白生成来使皮肤焕发活力的最新技术。Sofwave™ 的同步超声平行光束技术已获得 FDA 批准，可用于提升眼部、颏下和颈部，并减少细纹和皱纹。'}></Introduction>
                                    <WhyComponent list={whyList} title='为什么选择 Sofwave™”？' />
                                    <div className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">Sofwave™ 是如何工作的？</h2>
                                        </div>
                                        <div>
                                            <div style={{ paddingBottom: '1.579rem', lineHeight: "28px", textIndent: '2em' }}>治疗皱纹的深度为真皮中部 1.5 毫米。我们独特的同步超声波平行束 SUPERB™ 技术可在真皮中部的精确深度产生热量。此外，还有一个集成的冷却机制 Sofcool™，可以保护最上面的皮肤层免遭灼伤。</div>
                                            <div style={{ paddingBottom: '1.579rem', lineHeight: "28px", textIndent: '2em' }}>受影响的治疗区域会刺激一种称为新胶原酶的愈合反应，它会增加和再生皮肤中的胶原蛋白，从而减少细纹和皱纹，并提升眉毛、颏下和颈部的效果。</div>
                                        </div>
                                    </div>

                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">服务项目</h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>脸部轮廓提拉和双下巴</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>眼部和抬头纹</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>颈部和双下巴</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>全脸提拉</span>
                                                            {/* <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>120 mins</span> */}
                                                        </li>


                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>玻尿酸瀑布护理</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90-120 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>氧气泡泡护理</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>90 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair styling</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair styling for occasion</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Consulting</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Trial hairstyling for occasion</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>All hair color</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Root color</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Highlights</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333' }}>Hair toning</span>
                                                            <span className="list-group-price" style={{ maxWidth: '80%',width: '100%', textAlign: 'left'}}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInside9;