import React from 'react';

const Introduction = ({ list,title=''}) => {
    // 确保 list 不是 null 或 undefined，并且是一个数组
    if (!Array.isArray(list) || list.length === 0) {
        return (
            <div className="block spacer p-top-xl">
            </div>
        );
    }

    return (
        <div className="block spacer p-top-xl">
            <div className="title">
                <h2 className="hr">{title}</h2>
            </div>
            <div>
                {list.length > 0 && list.map(item => {
                    return <div style={{padding:'1rem 0'}}>
                        {item.title&&<h5 style={{fontWeight:'600',color:'#333',fontSize:'19px'}}>{item.title}</h5>}
                        {item.content && <div style={{ borderBottom:'1px solid #457f88',paddingBottom:'1.579rem',lineHeight:"28px",color:"#262626"}}>{item.content}</div>}
                    </div>
                })}
            </div>
        </div>
    );
};

export default Introduction
