import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServicesHomeList extends Component {
    render() {
        const list = [{
            title: "基础皮肤护理",
            list1: [{ title: "基础护理系列", link: '/services-inside2' },
            { title: "WISH Pro 时空胶囊", link: '/services-inside14' },
            { title: "至奢抗衰护理系列", link: '/services-inside' },
            { title: "眼部抗老护理", link: '/services-inside3' },
            ],
            bgImg: "url(assets/img/placeholder/基础护理1.png)",
        }, {
            title: "治疗性项目",
            list1: [{ title: "干细胞治疗", link: '/services-inside7' },
            { title: "黄金微针", link: '/services-inside6' },
            { title: "水光焕亮", link: '/services-inside8' },
            { title: "索夫波", link: '/services-inside9' },
            { title: "ULTRAFORMER III", link: '/services-inside11' },
            { title: "Ultraformer MPT", link: '/services-inside12' },
            ],
            bgImg: "url(assets/img/placeholder/黄金微针.jpg)",
        }, {
            title: "激光治疗",
            list1: [{ title: "Lucas 激光", link: '/services-inside16' },
            { title: "Harmony 激光", link: '/services-inside15' },
            { title: "Spectra 激光", link: '/services-inside13' }],
            bgImg: "url(assets/img/placeholder/水光针图片.png)",
        },]
        const list2 = [{
            title: "身体舒缓",
            list1: [{ title: "身体淋巴排毒按摩", link: "/services-inside4" },
            { title: "拔罐", link: "/services-inside4" },
            { title: "艾灸", link: "/services-inside4" },
            { title: "刷脂", link: "/services-inside4" },
            { title: "刮痧", link: "/services-inside4" }],
            bgImg: "url(assets/img/placeholder/身体舒缓护理.webp)"
        }, {
            title: "日式整骨系列",
            list1: [{ title: "3D 小颜整骨", link: "/services-inside5" },
            { title: "长短脚调整", link: "/services-inside5" },
            { title: "含胸驼背调整", link: "/services-inside5" },
            { title: "肋骨外翻矫正", link: "/services-inside5" },
            { title: "耻骨矫正", link: "/services-inside5" },
            { title: "盆骨矫正", link: "/services-inside5" }],
            bgImg: "url(assets/img/placeholder/整骨.jpg)"
        }, {
            title: "产后修复",
            list1: [{ title: "腹直肌修复", link: "/services-inside17" },
            { title: "假胯宽修复", link: "/services-inside17" },
            { title: "耻骨修复", link: "/services-inside17" },
            { title: "盆骨修复", link: "/services-inside17" }],
            bgImg: "url(assets/img/placeholder/产后修复2.jpg)"
        },]

        const bgBox = {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            filter: 'blur(5px) brightness(0.5)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backdropFilter: 'blur(2px)',
            zIndex: '-1',
            borderRadius: '8px'
        }

        const style = {
            padding: '40px 20px',
            textAlign: 'center',
            borderRadius: '8px',
            height: "100%",
            display: "flex",
            flexDirection: "column",
            color: "rgb(239, 230, 232)"
        }

        return (
            <div className='p-top-xl block spacer' style={{ padding: '0 3rem' }}>
                <h2 style={{ textAlign: 'center', paddingBottom: "2rem" }}>我们的服务</h2>
                <div>

                    <div style={{ padding: "2rem", background: 'rgb(239, 230, 232)', borderRadius: '10px', marginBottom: '2rem' }}>
                        <h2 style={{ textAlign: 'center' }}>皮肤管理</h2>
                        <div className='row gutter-width-sm' style={{ position: 'relative' }}>
                            {list.map(item => {
                                return (
                                    <div className='col-xl-4 col-lg-4 col-md-8 col-sm-12' key={item.title}>
                                        <div style={{ ...style, position: "relative", zIndex: 2 }}>
                                            <div>
                                                <h3 style={{ paddingBottom: '10px', color: "#fff" }}>{item.title}</h3>
                                                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
                                                    {item.list1.map(items => {
                                                        return (
                                                            <Link style={{ lineHeight: "50px", fontSize: '24px', color: "rgb(239, 230, 232)" }} to={items.link}>{items.title}</Link>
                                                        )
                                                    })}
                                                </div>
                                                <div style={{ ...bgBox, backgroundImage: item.bgImg }}></div>

                                            </div>

                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                    <div className='row gutter-width-sm'>
                        {list2.map(item => {
                            return (
                                <div className='col-xl-4 col-lg-4 col-md-8 col-sm-12' key={item.title} style={{ marginBottom: '2rem' }}>
                                    <div style={{ ...style, position: "relative" }}>
                                        <div>
                                            <h3 style={{ paddingBottom: '10px', color: "#fff" }}>{item.title}</h3>
                                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
                                                {item.list1.map(items => {
                                                    return (
                                                        <Link style={{ lineHeight: "50px", fontSize: '24px', color: "rgb(239, 230, 232)" }} to={items.link}>{items.title}</Link>
                                                    )
                                                })}
                                            </div>
                                            <div style={{ ...bgBox, backgroundImage: item.bgImg }}></div>

                                        </div>

                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    {/* <div className='col-xl-4 col-lg-4 col-md-8 col-sm-12' style={{ marginBottom: '20px' }}>
                        <div style={{ ...style, position: "relative" }}>
                            <div>
                                <h3 style={{ paddingBottom: '10px', color: '#fff' }}>皮肤管理</h3>
                                <div className='row' style={{ justifyContent: "space-around" }}>
                                    <div style={{ flex: 1 }}>
                                        <h4 style={{ color: "rgb(239, 230, 232)" }}>手法+仪器类</h4>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside2'}>基础护理系列</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside14'}>WISH Pro</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside'}>至奢抗衰护理系列</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside3'}>眼部抗老护理</Link>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <h4 style={{ color: "rgb(239, 230, 232)" }}>大型仪器类</h4>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside7'}>干细胞治疗</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside6'}>黄金微针</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside8'}>水光焕亮</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside9'}>索夫波</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside11'}>ULTRAFORMER III</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-inside12'}>10D MPT</Link>
                                    </div>
                                </div>
                                <div style={{ ...bgBox, backgroundImage: "url(assets/img/placeholder/至奢大图.png)" }}></div>

                            </div>


                        </div>

                    </div> */}

                </div>
            </div>
        );
    }
}

export default ServicesHomeList;