import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleHome from '../blocks/page-title/PageTitleHome';
import ContactsUs from "../blocks/contacts/ContactUs";
import ServicesHome from '../blocks/services/ServicesHome';
import ServicesHomeList from '../blocks/services/ServicesHomeList';
import AboutsUs from '../blocks/about/AboutUs';
import News from '../blocks/news/News';
import Newsletter from '../blocks/newsletter/Newsletter';
import { Link } from 'react-router-dom';


const Home = () => {
    document.body.classList.add('home');
    document.body.classList.add('header-absolute-true');

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Laydeez skin laser clinic</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="light" />

            <main id="main" className="site-main content-no-spacing">
                <div className="content">
                    <div className="clearfix">
                        <PageTitleHome />


                        <ServicesHome />

                        {/* <AboutsUs /> */}

                        <div style={{ padding: '7.368rem 0', backgroundColor: "#efe6e8", marginTop: "7.368rem" }}>
                            <h2 style={{ textAlign: 'center', paddingBottom: '3.158rem', marginottom: '0' }}>为什么选择我们？</h2>
                            <div style={{ fontSize: "24px", padding: "0 7.368rem", lineHeight: "30px", textIndent: "3rem" }}>Laydeez 是一家专注于皮肤管理、身体护理和产后修复的诊所，致力于为女性提供全面的护理和支持。我们提供一系列专业的皮肤管理疗程、身体护理和产后修复方案，帮助女性在身体和心理上重获自信。我们的团队由经验丰富的专业人士组成，采用先进的技术、设备和个性化的护理方案，确保每位客户都能获得最佳的体验与效果。无论是想要改善皮肤或身体状态，还是需要帮助恢复产后形体，Laydeez 都是你理想的选择。欢迎加入我们，开启你的美丽与修复之旅！</div>
                        </div>

                        <ServicesHomeList/>

                        {/* <News />

                        <Newsletter /> */}

                        {/* <div id="img" className="block spacer" style={{}}>
                            <div className="img-no-wrap-1 ">
                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/大图.png" alt="Beauty Salon" />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                    <ContactsUs />

                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Home;
